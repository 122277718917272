/**
 * ニュース
 * slick使用
 */
import { BREAKPOINT_PC, BREAKPOINT_SP } from '../define/define'

const mq_pc = window.matchMedia(`(min-width: ${BREAKPOINT_PC}px)`)
const itemClass = `.js-newsItem`

export default function() {

  document.addEventListener(`DOMContentLoaded`, function(){

    const newsNum = jQuery(itemClass).length
    const $newsItems = jQuery(`#newsItems`)

    // 次へボタンクリック
    jQuery(`#newsNextButton`).on(`click.newsbutton`, function(){
      $(this).blur()
      $newsItems.slick(`slickNext`)
    })

    // 前へボタンクリック
    jQuery(`#newsPrevButton`).on(`click.newsbutton`, function(){
      $(this).blur()
      $newsItems.slick(`slickPrev`)
    })

    // NEWSカルーセル設定
    $newsItems.slick({
      accessibility: false,
      autoplaySpeed: 5000,
      speed: 1000,
      arrows: false,
      draggable: false,
      touchMove: false,
      vertical: true,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: BREAKPOINT_SP,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    })

    // スライド前
    $newsItems.on(`beforeChange`, function(event, slick, currentSlide, nextSlide){
      jQuery(`#newsNextButton, #newsPrevButton`).off(`click.newsbutton`)
    })

    // スライド後
    $newsItems.on(`afterChange`, function(event, slick, currentSlide){
      jQuery(`#newsNextButton`).on(`click.newsbutton`, function(){
        $(this).blur()
        $newsItems.slick(`slickNext`)
      })
  
      jQuery(`#newsPrevButton`).on(`click.newsbutton`, function(){
        $(this).blur()
        $newsItems.slick(`slickPrev`)
      })
    })

    if( mq_pc.matches ) {
      if( newsNum < 4 ) {
        $newsItems.slick(`unslick`)
        $(`.c-news__arrow-container`).addClass(`is-pcHidden`)
      }
    } else {
      if( newsNum < 2 ) {
        $newsItems.slick(`unslick`)
      } else if(newsNum < 4) {
        $(`.c-news__arrow-container`).addClass(`is-pcHidden`)
      }
    }

    mq_pc.addListener(function(){
      if( mq_pc.matches ) {
        if( newsNum < 4 ) {
          $newsItems.slick(`unslick`)
          $(`.c-news__arrow-container`).addClass(`is-pcHidden`)
        }
      } else {
        if( (newsNum > 1) && (newsNum < 4) ) {
          $newsItems.slick({
            accessibility: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 1000,
            arrows: false,
            draggable: false,
            touchMove: false,
            vertical: true,
            slidesToShow: 3,
            infinite: false,
            responsive: [
              {
                breakpoint: BREAKPOINT_SP,
                settings: {
                  slidesToShow: 1,
                }
              }
            ]
          })
        }
      }
    })

    // NEWSがwindowの指定位置まで来たらカルーセルスタート
    startNewsCarousel()

  })

}

/**
 * NEWSがwindowの指定位置まで来たらカルーセルスタート
 */
function startNewsCarousel() {
  jQuery(window).on('scroll.newsCarousel', function (){

    const elemOffset = jQuery(`#news`).offset().top;
    const scrollPos = jQuery(window).scrollTop();
    const wh = jQuery(window).height();

    if(scrollPos > elemOffset - wh + (wh / 2) ){
      jQuery(`#newsItems`).slick(`slickPlay`)
      jQuery(window).off(`scroll.newsCarousel`)
    }
  
  })
}
