/**
 * メニュー表示/非表示
 */
export default function() {

  document.addEventListener(`DOMContentLoaded`, function(){

    jQuery(`.js-menu-trigger`).on(`click`, function(){
      jQuery(this).blur()

      // bodyを固定/固定解除、IE11用クラスをhtmlタグに追加
      jQuery(`body`).toggleClass(`u-menuBackItem`)
      jQuery(`html`).toggleClass(`u-overflow-hidden-ie11`)

      jQuery(`#menu`).slideToggle(350)
      jQuery(`#menuLogo`).toggleClass(`is-disp`)
    })

  })

}
