/**
 * メニュー背景画像の切り替え
 */
export default function() {

  document.addEventListener(`DOMContentLoaded`, function(){

    let dataName = ``

    jQuery(`.js-menuBgSwitch-trigger`).hover(
      function(){
        dataName = jQuery(this).data(`name`)
        document.getElementById(`menuPcBg`).classList.add(`is-${dataName}`)
      },
      function(){
        document.getElementById(`menuPcBg`).classList.remove(`is-${dataName}`)
      },
    )

  })

}
