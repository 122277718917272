/**
 * SP時のMVの高さを設定
 */
export default function() {

  document.addEventListener(`DOMContentLoaded`, function(){

    const mv_el = document.getElementById(`mv`)

    if( !(mv_el) ) return


    const mq_sp = window.matchMedia(`(max-width: 767px)`)
    const langTopHeaderSpHeight = 60
    let mvSpHeight = window.innerHeight  - langTopHeaderSpHeight

    if ( mq_sp.matches ) {
      mv_el.style.height = `${mvSpHeight}px`
    }

    window.addEventListener(`resize`, ()=>{
      mvSpHeight = window.innerHeight  - langTopHeaderSpHeight
      if ( mq_sp.matches ) {
        mv_el.style.height = `${mvSpHeight}px`

      } else {
        mv_el.removeAttribute(`style`)
      }
    })

  })

}
