/**
 * SP時に言語選択メニューを表示する
 */
export default function() {

  document.addEventListener(`DOMContentLoaded`, ()=>{

    jQuery(`.js-dispSpLangChoice-trigger`).on(`click`, function(){
      jQuery(this).blur()
      jQuery(`#spLangChoice`).slideToggle(300)
    })

    // リサイズ時にPCだった場合、メニューを非表示にする
    const mq_pc = window.matchMedia(`(min-width: 768px)`)

    mq_pc.addListener(()=>{
      if( mq_pc.matches ) {
        jQuery(`#spLangChoice`).css(`display`, `none`)
      }
    })

  })

}
