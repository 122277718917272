// PromiseがIE11では使用できないためpolyfillを追加
var Promise = require('es6-promise').Promise;

// sp or pcの判別
import checkUserAgent from './helper/checkUserAgent';
import { setAutoHeight } from './helper/height';
// MV背景画像のカルーセル
import mvBgCarousel from './component/mvBgCarousel'

let userAgent = checkUserAgent()
let currentWidth = window.innerWidth;


// ==========
// リサイズ処理
// ==========
window.addEventListener('resize',function(){
  "use strict";
  // SPで且つwidth幅が変わらない(縦向き←→横向きをしていない)時はリサイズしない。
  if (userAgent === 'sp' && currentWidth === window.innerWidth) {
    return;
  }
  // setAutoHeight(userAgent);
}, false);

// ==========
// DOM、画像読み込み後に実行
// ==========
window.addEventListener('load',function(){
  "use strict";
  // 引数を追加してheightの設定
  // setAutoHeight(userAgent);
  // asyncAnimation().then((res) => {console.log(res)});

  if($(".bg__fader").hasClass("_disabled_")) {
    // 言語選択ページ
    pageAnimation(1, 0) // ページ表示時にアニメーションを実行させない。
      // .then(() => { // タイトル非表示
      //   $(".animationTitle").css('display', 'block');
      //   $(".animationTitle").textillate({in:{effect: 'fadeInUp'}})
      // })
      .then(() => {
        setTimeout(() => {
          $(".animationMenu").css('display', 'block');
          $(".animationMenu").textillate({in:{effect: 'fadeInUp',sync: true}});
          // リンク押下時にはアニメーションがつくようにする。
          $(".bg__fader").removeClass("_disabled_");
        } ,500)
      })
      // .then(() => {
      //   setTimeout(() => {
      //     $(".animationFooter").css('display', 'block');
      //     $(".animationFooter-inline").css('display', 'inline-block');
      //     $(".animationFooter").textillate({in:{effect: 'fadeInUp',sync: true}});
      //     // リンク押下時にはアニメーションがつくようにする。
      //     $(".bg__fader").removeClass("_disabled_");
      //   },1500)
      // })
      .catch(onRejected);
  } else {
    /*
    言語トップページ
    */
    // MV背景画像のカルーセル
    const carouselObject = mvBgCarousel()

    // ページ遷移時アニメーション
    pageAnimation(1500, 2000, carouselObject)
  }
}, false);

// ページ遷移時アニメーション
function pageAnimation(durationTime, delayTime, carouselObject) {
  return new Promise((resolve, reject) => {
    $(".animsition").animsition({
      inClass: 'fade-in', // ロード時のエフェクト
      outClass: 'fade-out', // 離脱時のエフェクト
      inDuration: durationTime, // ロード時の演出時間
      outDuration: 800, // 離脱時の演出時間
      linkElement: 'a:not([target="_blank"]):not([href^="#"])', // アニメーションを行う要素
      // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
      loading: true, // ローディングの有効/無効
      loadingParentElement: 'body', // ローディング要素のラッパー
      loadingClass: 'animsition-loader', // ローディングのクラス
      loadingInner: '', // e.g '' ローディングの内容
      timeout: false, // 一定時間が経ったらアニメーションをキャンセルの有効/無効
      timeoutCountdown: 10000, // アニメーションをキャンセルするまでの時間
      onLoadEvent: true, // onLoadイベント後にアニメーションをするかの有効/無効
      browser: [ 'animation-duration', '-webkit-animation-duration'],
      // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
      // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
      // ブラウザが配列内のCSSプロパティをサポートしていない場合、アニメーションを中止します。デフォルトは「animation-duration」をサポートしていない場合です。
      overlay : false, // オーバーレイの有効/無効
      overlayClass : 'animsition-overlay-slide', // オーバーレイのクラス
      overlayParentElement : 'body', // オーバーレイ要素のラッパー
      transition: function(url){ window.location.href = url; } // transition後にどこに遷移させるかを設定、urlは「linkElement」のhref
    });
    setTimeout(function() {
      resolve();

      $(`#bgFaderLangTop`).fadeOut(250, function(){
        if( carouselObject ) {
          carouselObject.slickPlay()
          document.getElementById(`mvBar`).classList.add(`is-start`)
        }

        jQuery(`#bgFaderLangTop`).remove()
      })
    }, delayTime);
    // resolve();
  })
}

function onRejected(error) {console.log(`error: ${error}`)};


// 【ヘッダー】 PC時に表示するCONTACT表示
import dispPcContact from './component/dispPcContact'
dispPcContact()

// SP時に言語選択メニューを表示する
import dispSpLangMenu from './component/dispSpLangMenu'
dispSpLangMenu()

// SP時のMVの高さを設定
import mvSpHeightSet from './component/mvSpHeightSet'
mvSpHeightSet()

// メニュー表示/非表示
import menuDisp from './component/menuDisp'
menuDisp()

// メニュー背景画像の切り替え
import menuBgSwitch from './component/menuBgSwitch'
menuBgSwitch()

// ニュース
import news from './component/news'
news()

// 複数行で表示される文字列に対して省略記号「…」で省略する
import textOverflowEllipsis from './component/textOverflowEllipsis.jquery'
textOverflowEllipsis()
