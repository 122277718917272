/**
 * 【ヘッダー】 PC時に表示するCONTACT表示
 * slideToggle表示
 */
export default function() {

  document.addEventListener(`DOMContentLoaded`, ()=>{

    jQuery(`#headerContact`).hover(
      function(){
        jQuery(`.js-headerContactTarget`).fadeIn()
      },
      function(){
        jQuery(`.js-headerContactTarget`).fadeOut(200)
      }
    )

  })

}
