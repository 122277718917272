/**
 * MV背景画像のカルーセル
 * slickを使用
 */
export default function() {

  jQuery(`#mvBg`).slick({
    accessibility: false,
    autoplaySpeed: 2000,
    speed: 5000,
    arrows: false,
    draggable: false,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    touchMove: false,
  })

  const slickObject = jQuery(`#mvBg`).slick(`getSlick`)

  return slickObject

}
